/* global Office console */

import { ListboxOption, Switch } from "@microsoft/fast-components";
import {
  endDateInput,
  formatSwitch,
  startDateInput,
  tableLocationInput,
  autoUpdateSwitch,
  offsetInput,
} from "../taskpane";
import $ from "jquery";

export const KEY_WORKSHEET_NAME = "market-data-worksheet-name";
export const KEY_TABLE_LOCATION = "market-data-table-location";
export const KEY_RAW_FORMAT = "market-data-format";
export const KEY_START_DATE = "market-data-start-date";
export const KEY_END_DATE = "market-data-end-date";
export const KEY_SELECTED_CURVES = "selected-curve-names";
const KEY_AUTO_UPDATE = "market-data-auto-update";
const KEY_OFFSET = "market-data-days-offset";

export function loadCache() {
  const worksheetName = readItemFromStorage(KEY_WORKSHEET_NAME);
  $("#worksheet-name").val(worksheetName ?? "");

  const tableLocation = readItemFromStorage(KEY_TABLE_LOCATION);
  $(tableLocationInput).val(tableLocation ?? "");

  const format = readItemFromStorage(KEY_RAW_FORMAT);
  $<Switch>(formatSwitch).first().get(0).checked = format === "true";

  const startDate = readItemFromStorage(KEY_START_DATE);
  $(startDateInput).val(startDate);

  const endDate = readItemFromStorage(KEY_END_DATE);
  $(endDateInput).val(endDate);

  const autoUpdate = readItemFromStorage(KEY_AUTO_UPDATE);
  $<Switch>(autoUpdateSwitch).first().get(0).checked = autoUpdate === "true";

  const offset = readItemFromStorage(KEY_OFFSET);
  $(offsetInput).val(offset ?? 0);
}

export function cacheSettings() {
  const worksheetName = $("#worksheet-name").val() ?? "B2";
  worksheetName
    ? saveItemInStorage(KEY_WORKSHEET_NAME, worksheetName.toString())
    : removeItemFromStorage(KEY_WORKSHEET_NAME);

  const tableLocation = $(tableLocationInput).val();
  tableLocation
    ? saveItemInStorage(KEY_TABLE_LOCATION, tableLocation.toString())
    : removeItemFromStorage(KEY_TABLE_LOCATION);

  cacheDates();
  cacheAutoUpdate();
  cacheFormat();
}

export function cacheDates() {
  const worksheetName = $(startDateInput).val() ?? "B2";
  worksheetName ? saveItemInStorage(KEY_START_DATE, worksheetName.toString()) : removeItemFromStorage(KEY_START_DATE);

  const tableLocation = $(endDateInput).val();
  tableLocation ? saveItemInStorage(KEY_END_DATE, tableLocation.toString()) : removeItemFromStorage(KEY_END_DATE);
}

export function saveSelection(selectedCurves: ListboxOption[]) {
  const selected = JSON.stringify(selectedCurves.map((o) => o.value));
  saveItemInStorage(KEY_SELECTED_CURVES, selected);
}

export function loadSelection() {
  const selectedJson = readItemFromStorage(KEY_SELECTED_CURVES);
  if (!selectedJson) return [];

  const selected = JSON.parse(selectedJson);
  return selected;
}

export function cacheAutoUpdate() {
  const el = $<Switch>(autoUpdateSwitch).first().get(0);
  saveItemInStorage(KEY_AUTO_UPDATE, el.checked.toString());
}

export function cacheFormat() {
  const el = $<Switch>(formatSwitch).first().get(0);
  saveItemInStorage(KEY_RAW_FORMAT, el.checked.toString());
}

function readItemFromStorage(key: string) {
  try {
    return Office.context.document.settings.get(key);
  } catch (e) {
    throw new Error("Could not read:" + key);
  }
}

function saveItemInStorage(key: string, value: any) {
  Office.context.document.settings.set(key, value);
}

export function persistCache() {
  Office.context.document.settings.saveAsync((res) => {
    if (res.status === Office.AsyncResultStatus.Succeeded) {
      console.info("Settings saved");
    } else {
      console.warn("Failed to save", res.error);
    }
  });
}

function removeItemFromStorage(key: string) {
  try {
    Office.context.document.settings.remove(key);
  } catch (_) {
    throw new Error("Nothig to remove: " + key);
  }
}
