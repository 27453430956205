export const GRID_MAP: Record<string, string> = {
  "Volue pri at spot €/mwh cet h a": "AT",
  "Volue pri be spot €/mwh cet h a": "BE",
  "Volue pri de spot €/mwh cet h a": "DE",
  "Volue pri dk1 spot €/mwh cet h a": "DK1",
  "Volue pri dk2 spot €/mwh cet h a": "DK2",
  "Volue pri fi spot €/mwh cet h a": "FI",
  "Volue pri fr spot €/mwh cet h a": "FR",
  "Volue pri nl spot €/mwh cet h a": "NL",
  "Volue pri no1 spot €/mwh cet h a": "NO1",
  "Volue pri no2 spot €/mwh cet h a": "NO2",
  "Volue pri se1 spot €/mwh cet h a": "SE1",
  "Volue pri se2 spot €/mwh cet h a": "SE2",
  "Volue pri se3 spot €/mwh cet h a": "SE3",
  "Volue pri se4 spot €/mwh cet h a": "SE4",
  "Volue pri uk spot epex £/mwh cet h a": "UK EPEX £",
  "Volue pri uk spot epex €/mwh cet h a": "UK EPEX €",
  "Volue pri uk spot nord-pool £/mwh cet h a": "UK N2EX £",
  "Volue pri uk spot nord-pool €/mwh cet h a": "UK N2EX €",
  "Volue pri no3 spot €/mwh cet h a": "NO3",
  "Volue pri no4 spot €/mwh cet h a": "NO4",
  "Volue pri no5 spot €/mwh cet h a": "NO5",
};

export const SORTED_CURVES = {
  Unsorted: [
    "Volue pri no3 spot €/mwh cet h a",
    "Volue pri no4 spot €/mwh cet h a",
    "Volue pri no5 spot €/mwh cet h a",
    "Volue pri fi spot €/mwh cet h a",
    "Volue pri de spot €/mwh cet h a",
    "Volue pri fr spot €/mwh cet h a",
    "Volue pri at spot €/mwh cet h a",
    "Volue pri be spot €/mwh cet h a",
    "Volue pri nl spot €/mwh cet h a",
    "Volue pri dk1 spot €/mwh cet h a",
    "Volue pri dk2 spot €/mwh cet h a",
    "Volue pri se1 spot €/mwh cet h a",
    "Volue pri se2 spot €/mwh cet h a",
    "Volue pri se3 spot €/mwh cet h a",
    "Volue pri se4 spot €/mwh cet h a",
    "Volue pri no1 spot €/mwh cet h a",
    "Volue pri no2 spot €/mwh cet h a",
    "Volue pri uk spot nord-pool £/mwh cet h a",
    "Volue pri uk spot epex £/mwh cet h a",
    "Volue pri uk spot nord-pool €/mwh cet h a",
    "Volue pri uk spot epex €/mwh cet h a",
    "energy_meteo_de_solar_prod",
    "energy_meteo_de_wind_prod",
    "energy_meteo_DK1_wind_prod",
    "energy_meteo_DK1_solar_prod",
    "energy_meteo_nl_wind_prod",
    "energy_meteo_nl_solar_prod",
    "energy_meteo_DK2_wind_prod",
    "energy_meteo_DK2_solar_prod",
    "energy_meteo_be_wind_prod",
    "energy_meteo_be_solar_prod",
    "energy_meteo_fr_wind_prod",
    "energy_meteo_fr_solar_prod",
    "energy_meteo_at_wind_prod",
    "energy_meteo_gb_solar_prod",
    "energy_meteo_gb_wind_prod",
    "energy_meteo_ie_wind_prod",
    "energy_meteo_es_wind_prod",
    "energy_meteo_fi_wind_prod",
    "energy_meteo_NO1_wind_prod",
    "energy_meteo_NO2_wind_prod",
    "energy_meteo_NO3_wind_prod",
    "energy_meteo_NO4_wind_prod",
    "energy_meteo_SE1_wind_prod",
    "energy_meteo_SE2_wind_prod",
    "energy_meteo_SE3_wind_prod",
    "energy_meteo_SE4_wind_prod",
    "energy_meteo_at_wind_prod_spot_delta",
    "energy_meteo_be_solar_prod_spot_delta",
    "energy_meteo_be_wind_prod_spot_delta",
    "energy_meteo_de_solar_prod_spot_delta",
    "energy_meteo_de_wind_prod_spot_delta",
    "energy_meteo_fr_solar_prod_spot_delta",
    "energy_meteo_fr_wind_prod_spot_delta",
    "energy_meteo_gb_solar_prod_spot_delta",
    "energy_meteo_gb_wind_prod_spot_delta",
    "energy_meteo_ie_wind_prod_spot_delta",
    "energy_meteo_nl_solar_prod_spot_delta",
    "energy_meteo_nl_wind_prod_spot_delta",
    "energy_meteo_nl_wind_prod_1h_delta",
    "energy_meteo_nl_solar_prod_1h_delta",
    "energy_meteo_be_wind_prod_1h_delta",
    "energy_meteo_be_solar_prod_1h_delta",
    "energy_meteo_fr_wind_prod_1h_delta",
    "energy_meteo_fr_solar_prod_1h_delta",
    "energy_meteo_at_wind_prod_1h_delta",
    "energy_meteo_gb_solar_prod_1h_delta",
    "energy_meteo_gb_wind_prod_1h_delta",
    "energy_meteo_ie_wind_prod_1h_delta",
    "energy_meteo_de_solar_prod_1h_delta",
    "energy_meteo_de_wind_prod_1h_delta",
    "energy_meteo_DK1_solar_prod_spot_delta",
    "energy_meteo_DK1_wind_prod_spot_delta",
    "energy_meteo_DK2_solar_prod_spot_delta",
    "energy_meteo_DK2_wind_prod_spot_delta",
    "energy_meteo_DK1_wind_prod_1h_delta",
    "energy_meteo_DK1_solar_prod_1h_delta",
    "energy_meteo_DK2_wind_prod_1h_delta",
    "energy_meteo_DK2_solar_prod_1h_delta",
    "energy_meteo_SE1_wind_prod_spot_delta",
    "energy_meteo_SE2_wind_prod_spot_delta",
    "energy_meteo_SE3_wind_prod_spot_delta",
    "energy_meteo_SE4_wind_prod_spot_delta",
    "energy_meteo_NO1_wind_prod_spot_delta",
    "energy_meteo_NO2_wind_prod_spot_delta",
    "energy_meteo_NO3_wind_prod_spot_delta",
    "energy_meteo_NO4_wind_prod_spot_delta",
    "energy_meteo_fi_wind_prod_spot_delta",
    "energy_meteo_SE1_wind_prod_1h_delta",
    "energy_meteo_SE2_wind_prod_1h_delta",
    "energy_meteo_SE3_wind_prod_1h_delta",
    "energy_meteo_SE4_wind_prod_1h_delta",
    "energy_meteo_NO1_wind_prod_1h_delta",
    "energy_meteo_NO2_wind_prod_1h_delta",
    "energy_meteo_NO3_wind_prod_1h_delta",
    "energy_meteo_NO4_wind_prod_1h_delta",
    "energy_meteo_fi_wind_prod_1h_delta",
  ],
  "Spot prices": [
    "Volue pri de spot €/mwh cet h a",
    "Volue pri fr spot €/mwh cet h a",
    "Volue pri at spot €/mwh cet h a",
    "Volue pri be spot €/mwh cet h a",
    "Volue pri dk1 spot €/mwh cet h a",
    "Volue pri dk2 spot €/mwh cet h a",
    "Volue pri fi spot €/mwh cet h a",
    "Volue pri nl spot €/mwh cet h a",
    "Volue pri no1 spot €/mwh cet h a",
    "Volue pri no2 spot €/mwh cet h a",
    "Volue pri no3 spot €/mwh cet h a",
    "Volue pri no4 spot €/mwh cet h a",
    "Volue pri no5 spot €/mwh cet h a",
    "Volue pri se1 spot €/mwh cet h a",
    "Volue pri se2 spot €/mwh cet h a",
    "Volue pri se3 spot €/mwh cet h a",
    "Volue pri se4 spot €/mwh cet h a",
    "Volue pri uk spot epex £/mwh cet h a",
    "Volue pri uk spot epex €/mwh cet h a",
    "Volue pri uk spot nord-pool £/mwh cet h a",
    "Volue pri uk spot nord-pool €/mwh cet h a",
    "pri uk intraday vwap id1 £/mwh cet min30 ca",
    "pri uk intraday vwap id3 £/mwh cet min30 ca",
    "pri uk intraday vwap id30min £/mwh cet min30 ca",
    "pri uk intraday vwap last5min £/mwh cet min30 ca",
    "pri uk intraday vwap £/mwh cet min30 ca",
    "pri uk max intraday last5min £/mwh cet min30 ca",
    "pri uk min intraday last5min £/mwh cet min30 ca",
    "pri uk intraday vwap €/mwh cet min30 ca",
    "pri uk intraday auction €/mwh cet min30 a",
  ],
  "EM renewable production curves": [
    "energy_meteo_de_solar_prod",
    "energy_meteo_de_wind_prod",
    "energy_meteo_DK1_wind_prod",
    "energy_meteo_DK1_solar_prod",
    "energy_meteo_nl_wind_prod",
    "energy_meteo_nl_solar_prod",
    "energy_meteo_DK2_wind_prod",
    "energy_meteo_DK2_solar_prod",
    "energy_meteo_be_wind_prod",
    "energy_meteo_be_solar_prod",
    "energy_meteo_fr_wind_prod",
    "energy_meteo_fr_solar_prod",
    "energy_meteo_at_wind_prod",
    "energy_meteo_gb_solar_prod",
    "energy_meteo_gb_wind_prod",
    "energy_meteo_ie_wind_prod",
    "energy_meteo_es_wind_prod",
    "energy_meteo_fi_wind_prod",
    "energy_meteo_NO1_wind_prod",
    "energy_meteo_NO2_wind_prod",
    "energy_meteo_NO3_wind_prod",
    "energy_meteo_NO4_wind_prod",
    "energy_meteo_SE1_wind_prod",
    "energy_meteo_SE2_wind_prod",
    "energy_meteo_SE3_wind_prod",
    "energy_meteo_SE4_wind_prod",
    "energy_meteo_at_wind_prod_spot_delta",
    "energy_meteo_be_solar_prod_spot_delta",
    "energy_meteo_be_wind_prod_spot_delta",
    "energy_meteo_de_solar_prod_spot_delta",
    "energy_meteo_de_wind_prod_spot_delta",
    "energy_meteo_fr_solar_prod_spot_delta",
    "energy_meteo_fr_wind_prod_spot_delta",
    "energy_meteo_gb_solar_prod_spot_delta",
    "energy_meteo_gb_wind_prod_spot_delta",
    "energy_meteo_ie_wind_prod_spot_delta",
    "energy_meteo_nl_solar_prod_spot_delta",
    "energy_meteo_nl_wind_prod_spot_delta",
    "energy_meteo_nl_wind_prod_1h_delta",
    "energy_meteo_nl_solar_prod_1h_delta",
    "energy_meteo_be_wind_prod_1h_delta",
    "energy_meteo_be_solar_prod_1h_delta",
    "energy_meteo_fr_wind_prod_1h_delta",
    "energy_meteo_fr_solar_prod_1h_delta",
    "energy_meteo_at_wind_prod_1h_delta",
    "energy_meteo_gb_solar_prod_1h_delta",
    "energy_meteo_gb_wind_prod_1h_delta",
    "energy_meteo_ie_wind_prod_1h_delta",
    "energy_meteo_de_solar_prod_1h_delta",
    "energy_meteo_de_wind_prod_1h_delta",
    "energy_meteo_DK1_solar_prod_spot_delta",
    "energy_meteo_DK1_wind_prod_spot_delta",
    "energy_meteo_DK2_solar_prod_spot_delta",
    "energy_meteo_DK2_wind_prod_spot_delta",
    "energy_meteo_DK1_wind_prod_1h_delta",
    "energy_meteo_DK1_solar_prod_1h_delta",
    "energy_meteo_DK2_wind_prod_1h_delta",
    "energy_meteo_DK2_solar_prod_1h_delta",
    "energy_meteo_SE1_wind_prod_spot_delta",
    "energy_meteo_SE2_wind_prod_spot_delta",
    "energy_meteo_SE3_wind_prod_spot_delta",
    "energy_meteo_SE4_wind_prod_spot_delta",
    "energy_meteo_NO1_wind_prod_spot_delta",
    "energy_meteo_NO2_wind_prod_spot_delta",
    "energy_meteo_NO3_wind_prod_spot_delta",
    "energy_meteo_NO4_wind_prod_spot_delta",
    "energy_meteo_fi_wind_prod_spot_delta",
    "energy_meteo_SE1_wind_prod_1h_delta",
    "energy_meteo_SE2_wind_prod_1h_delta",
    "energy_meteo_SE3_wind_prod_1h_delta",
    "energy_meteo_SE4_wind_prod_1h_delta",
    "energy_meteo_NO1_wind_prod_1h_delta",
    "energy_meteo_NO2_wind_prod_1h_delta",
    "energy_meteo_NO3_wind_prod_1h_delta",
    "energy_meteo_NO4_wind_prod_1h_delta",
    "energy_meteo_fi_wind_prod_1h_delta",
  ],
  "VWAP of trades last 3 trading hours before delivery start of hourly contracts": [
    "Volue pri nl intraday vwap id3 €/mwh cet h a",
    "Volue pri fr intraday vwap id3 €/mwh cet h a",
    "Volue pri de intraday vwap id3 €/mwh cet min15 a",
    "Volue pri de intraday vwap id3 €/mwh cet h a",
    "Volue pri be intraday vwap id3 €/mwh cet h a",
    "Volue pri at intraday vwap id3 €/mwh cet min15 a",
    "Volue pri at intraday vwap id3 €/mwh cet h a",
  ],
  "VWAP of trades last trading hour before delivery start of hourly contracts": [
    "Volue pri nl intraday vwap id1 €/mwh cet h a",
    "Volue pri fr intraday vwap id1 €/mwh cet h a",
    "Volue pri de intraday vwap id1 €/mwh cet min15 a",
    "Volue pri de intraday vwap id1 €/mwh cet h a",
    "Volue pri be intraday vwap id1 €/mwh cet h a",
    "Volue pri at intraday vwap id1 €/mwh cet min15 a",
    "Volue pri at intraday vwap id1 €/mwh cet h a",
  ],
  "VWAP of trades last 30 trading minutes before delivery start of hourly contracts": [
    "Volue pri nl intraday vwap id30min €/mwh cet h a",
    "Volue pri fr intraday vwap id30min €/mwh cet h a",
    "Volue pri de intraday vwap id30min €/mwh cet min15 a",
    "Volue pri de intraday vwap id30min €/mwh cet h a",
    "Volue pri be intraday vwap id30min €/mwh cet h a",
    "Volue pri at intraday vwap id30min €/mwh cet min15 a",
    "Volue pri at intraday vwap id30min €/mwh cet h a",
  ],
  "Actual VWAPs of the EPEX Intraday continuously traded hourly contracts": [
    "Volue pri nl intraday €/mwh cet h a",
    "Volue pri be intraday €/mwh cet h a",
    "Volue pri de intraday €/mwh cet h a",
    "Volue pri fr intraday €/mwh cet h a",
    "Volue pri at intraday €/mwh cet h a",
    "Volue pri de intraday €/mwh cet min15 a     ",
    "Volue pri at intraday vwap €/mwh cet h a",
    "Volue pri at intraday vwap €/mwh cet min15 a",
    "Volue pri be intraday vwap €/mwh cet h a",
    "Volue pri de intraday vwap €/mwh cet h a",
    "Volue pri de intraday vwap €/mwh cet min15 a",
    "Volue pri fr intraday vwap €/mwh cet h a",
    "Volue pri nl intraday vwap €/mwh cet h a",
  ],
  "Actual VWAPs of the Nordpool Intraday continuously traded hourly contracts. Source: Nord Pool trades, VWAP internally calculated":
    [
      "Volue pri no1 intraday €/mwh cet h a",
      "Volue pri no2 intraday €/mwh cet h a",
      "Volue pri no3 intraday €/mwh cet h a",
      "Volue pri no4 intraday €/mwh cet h a",
      "Volue pri no5 intraday €/mwh cet h a",
      "Volue pri se1 intraday €/mwh cet h a",
      "Volue pri se2 intraday €/mwh cet h a",
      "Volue pri se3 intraday €/mwh cet h a",
      "Volue pri se4 intraday €/mwh cet h a",
      "Volue pri dk1 intraday €/mwh cet h a",
      "Volue pri dk2 intraday €/mwh cet h a",
      "Volue pri fi intraday €/mwh cet h a",
    ],
  Entsoe: [
    "entsoe_net_total_inflow_AT_calculated",
    "entsoe_net_total_inflow_DELU_calculated",
    "entsoe_net_total_inflow_DK1_calculated",
    "entsoe_net_total_inflow_DK2_calculated",
    "entsoe_net_total_inflow_NL_calculated",
    "entsoe_net_total_inflow_FR_calculated",
    "entsoe_scheduled_exc_AT>CH_day_ahead",
    "entsoe_scheduled_exc_AT>CH_total",
    "entsoe_scheduled_exc_AT>CZ_day_ahead",
    "entsoe_scheduled_exc_AT>CZ_total",
    "entsoe_scheduled_exc_AT>DELU_day_ahead",
    "entsoe_scheduled_exc_AT>DELU_total",
    "entsoe_scheduled_exc_AT>HU_day_ahead",
    "entsoe_scheduled_exc_AT>HU_total",
    "entsoe_scheduled_exc_AT>ITNORD_day_ahead",
    "entsoe_scheduled_exc_AT>ITNORD_total",
    "entsoe_scheduled_exc_AT>SI_day_ahead",
    "entsoe_scheduled_exc_AT>SI_total",
    "entsoe_scheduled_exc_BE>DELU_day_ahead",
    "entsoe_scheduled_exc_BE>DELU_total",
    "entsoe_scheduled_exc_BE>FR_day_ahead",
    "entsoe_scheduled_exc_BE>FR_total",
    "entsoe_scheduled_exc_BE>GB_day_ahead",
    "entsoe_scheduled_exc_BE>GB_total",
    "entsoe_scheduled_exc_BE>NL_day_ahead",
    "entsoe_scheduled_exc_BE>NL_total",
    "entsoe_scheduled_exc_CH>AT_day_ahead",
    "entsoe_scheduled_exc_CH>AT_total",
    "entsoe_scheduled_exc_CH>DELU_day_ahead",
    "entsoe_scheduled_exc_CH>DELU_total",
    "entsoe_scheduled_exc_CH>FR_day_ahead",
    "entsoe_scheduled_exc_CH>FR_total",
    "entsoe_scheduled_exc_CH>ITNORD_day_ahead",
    "entsoe_scheduled_exc_CH>ITNORD_total",
    "entsoe_scheduled_exc_CZ>AT_day_ahead",
    "entsoe_scheduled_exc_CZ>AT_total",
    "entsoe_scheduled_exc_CZ>DELU_day_ahead",
    "entsoe_scheduled_exc_CZ>DELU_total",
    "entsoe_scheduled_exc_DELU>AT_day_ahead",
    "entsoe_scheduled_exc_DELU>AT_total",
    "entsoe_scheduled_exc_DELU>BE_day_ahead",
    "entsoe_scheduled_exc_DELU>BE_total",
    "entsoe_scheduled_exc_DELU>CH_day_ahead",
    "entsoe_scheduled_exc_DELU>CH_total",
    "entsoe_scheduled_exc_DELU>CZ_day_ahead",
    "entsoe_scheduled_exc_DELU>CZ_total",
    "entsoe_scheduled_exc_DELU>DK1_day_ahead",
    "entsoe_scheduled_exc_DELU>DK1_total",
    "entsoe_scheduled_exc_DELU>DK2_day_ahead",
    "entsoe_scheduled_exc_DELU>DK2_total",
    "entsoe_scheduled_exc_DELU>FR_day_ahead",
    "entsoe_scheduled_exc_DELU>FR_total",
    "entsoe_scheduled_exc_DELU>NL_day_ahead",
    "entsoe_scheduled_exc_DELU>NL_total",
    "entsoe_scheduled_exc_DELU>NO2_day_ahead",
    "entsoe_scheduled_exc_DELU>NO2_total",
    "entsoe_scheduled_exc_DELU>PL_day_ahead",
    "entsoe_scheduled_exc_DELU>PL_total",
    "entsoe_scheduled_exc_DELU>SE4_day_ahead",
    "entsoe_scheduled_exc_DELU>SE4_total",
    "entsoe_scheduled_exc_DK1>DELU_day_ahead",
    "entsoe_scheduled_exc_DK1>DELU_total",
    "entsoe_scheduled_exc_DK1>DK2_day_ahead",
    "entsoe_scheduled_exc_DK1>DK2_total",
    "entsoe_scheduled_exc_DK1>GB_day_ahead",
    "entsoe_scheduled_exc_DK1>GB_total",
    "entsoe_scheduled_exc_DK1>NL_day_ahead",
    "entsoe_scheduled_exc_DK1>NL_total",
    "entsoe_scheduled_exc_DK1>NO2_day_ahead",
    "entsoe_scheduled_exc_DK1>NO2_total",
    "entsoe_scheduled_exc_DK1>SE3_day_ahead",
    "entsoe_scheduled_exc_DK1>SE3_total",
    "entsoe_scheduled_exc_DK2>DELU_day_ahead",
    "entsoe_scheduled_exc_DK2>DELU_total",
    "entsoe_scheduled_exc_DK2>DK1_day_ahead",
    "entsoe_scheduled_exc_DK2>DK1_total",
    "entsoe_scheduled_exc_DK2>SE4_day_ahead",
    "entsoe_scheduled_exc_DK2>SE4_total",
    "entsoe_scheduled_exc_ES>FR_day_ahead",
    "entsoe_scheduled_exc_ES>FR_total",
    "entsoe_scheduled_exc_FI>SE1_day_ahead",
    "entsoe_scheduled_exc_FI>SE1_total",
    "entsoe_scheduled_exc_FI>SE3_day_ahead",
    "entsoe_scheduled_exc_FI>SE3_total",
    "entsoe_scheduled_exc_FR>BE_day_ahead",
    "entsoe_scheduled_exc_FR>BE_total",
    "entsoe_scheduled_exc_FR>CH_day_ahead",
    "entsoe_scheduled_exc_FR>CH_total",
    "entsoe_scheduled_exc_FR>DELU_day_ahead",
    "entsoe_scheduled_exc_FR>DELU_total",
    "entsoe_scheduled_exc_FR>ES_day_ahead",
    "entsoe_scheduled_exc_FR>ES_total",
    "entsoe_scheduled_exc_FR>GBELECLINK_day_ahead",
    "entsoe_scheduled_exc_FR>GBELECLINK_total",
    "entsoe_scheduled_exc_FR>GBIFA_day_ahead",
    "entsoe_scheduled_exc_FR>GBIFA_total",
    "entsoe_scheduled_exc_FR>GBIFA2_day_ahead",
    "entsoe_scheduled_exc_FR>GBIFA2_total",
    "entsoe_scheduled_exc_FR>ITNORD_day_ahead",
    "entsoe_scheduled_exc_FR>ITNORD_total",
    "entsoe_scheduled_exc_GB>BE_day_ahead",
    "entsoe_scheduled_exc_GB>BE_total",
    "entsoe_scheduled_exc_GB>DK1_day_ahead",
    "entsoe_scheduled_exc_GB>DK1_total",
    "entsoe_scheduled_exc_GB>IESEM_day_ahead",
    "entsoe_scheduled_exc_GB>IESEM_total",
    "entsoe_scheduled_exc_GB>NL_day_ahead",
    "entsoe_scheduled_exc_GB>NL_total",
    "entsoe_scheduled_exc_GB>NO2_day_ahead",
    "entsoe_scheduled_exc_GB>NO2_total",
    "entsoe_scheduled_exc_GBELECLINK>FR_day_ahead",
    "entsoe_scheduled_exc_GBELECLINK>FR_total",
    "entsoe_scheduled_exc_GBIFA>FR_day_ahead",
    "entsoe_scheduled_exc_GBIFA>FR_total",
    "entsoe_scheduled_exc_GBIFA2>FR_day_ahead",
    "entsoe_scheduled_exc_GBIFA2>FR_total",
    "entsoe_scheduled_exc_HU>AT_day_ahead",
    "entsoe_scheduled_exc_HU>AT_total",
    "entsoe_scheduled_exc_IESEM>GB_day_ahead",
    "entsoe_scheduled_exc_IESEM>GB_total",
    "entsoe_scheduled_exc_ITNORD>AT_day_ahead",
    "entsoe_scheduled_exc_ITNORD>AT_total",
    "entsoe_scheduled_exc_ITNORD>CH_day_ahead",
    "entsoe_scheduled_exc_ITNORD>CH_total",
    "entsoe_scheduled_exc_ITNORD>FR_day_ahead",
    "entsoe_scheduled_exc_ITNORD>FR_total",
    "entsoe_scheduled_exc_NL>BE_day_ahead",
    "entsoe_scheduled_exc_NL>BE_total",
    "entsoe_scheduled_exc_NL>DELU_day_ahead",
    "entsoe_scheduled_exc_NL>DELU_total",
    "entsoe_scheduled_exc_NL>DK1_day_ahead",
    "entsoe_scheduled_exc_NL>DK1_total",
    "entsoe_scheduled_exc_NL>GB_day_ahead",
    "entsoe_scheduled_exc_NL>GB_total",
    "entsoe_scheduled_exc_NL>NO2_day_ahead",
    "entsoe_scheduled_exc_NL>NO2_total",
    "entsoe_scheduled_exc_NO2>DELU_day_ahead",
    "entsoe_scheduled_exc_NO2>DELU_total",
    "entsoe_scheduled_exc_NO2>DK1_day_ahead",
    "entsoe_scheduled_exc_NO2>DK1_total",
    "entsoe_scheduled_exc_NO2>GB_day_ahead",
    "entsoe_scheduled_exc_NO2>GB_total",
    "entsoe_scheduled_exc_NO2>NL_day_ahead",
    "entsoe_scheduled_exc_NO2>NL_total",
    "entsoe_scheduled_exc_PL>DELU_day_ahead",
    "entsoe_scheduled_exc_PL>DELU_total",
    "entsoe_scheduled_exc_SE1>FI_day_ahead",
    "entsoe_scheduled_exc_SE1>FI_total",
    "entsoe_scheduled_exc_SE3>DK1_day_ahead",
    "entsoe_scheduled_exc_SE3>DK1_total",
    "entsoe_scheduled_exc_SE3>FI_day_ahead",
    "entsoe_scheduled_exc_SE3>FI_total",
    "entsoe_scheduled_exc_SE4>DELU_day_ahead",
    "entsoe_scheduled_exc_SE4>DELU_total",
    "entsoe_scheduled_exc_SE4>DK2_day_ahead",
    "entsoe_scheduled_exc_SE4>DK2_total",
    "entsoe_scheduled_exc_SI>AT_day_ahead",
    "entsoe_scheduled_exc_SI>AT_total",
  ],
  "Epex Prices": [
    "epex_IDA1_price_dk1_sftp",
    "epex_IDA1_price_dk2_sftp",
    "epex_IDA1_price_se1_sftp",
    "epex_IDA1_price_se2_sftp",
    "epex_IDA1_price_se3_sftp",
    "epex_IDA1_price_se4_sftp",
    "epex_IDA1_price_no1_sftp",
    "epex_IDA1_price_no2_sftp",
    "epex_IDA1_price_no3_sftp",
    "epex_IDA1_price_no4_sftp",
    "epex_IDA1_price_no5_sftp",
    "epex_IDA1_price_de_sftp",
    "epex_IDA1_price_at_sftp",
    "epex_IDA1_price_uk_sftp",
    "epex_IDA1_price_fr_sftp",
    "epex_IDA1_price_be_sftp",
    "epex_IDA1_price_fi_sftp",
    "epex_IDA1_price_nl_sftp",
    "epex_IDA1_price_ee_sftp",
    "epex_IDA1_price_lt_sftp",
    "epex_IDA1_price_lv_sftp",
    "epex_IDA1_price_pl_sftp",
    "epex_IDA2_price_dk1_sftp",
    "epex_IDA2_price_dk2_sftp",
    "epex_IDA2_price_se1_sftp",
    "epex_IDA2_price_se2_sftp",
    "epex_IDA2_price_se3_sftp",
    "epex_IDA2_price_se4_sftp",
    "epex_IDA2_price_no1_sftp",
    "epex_IDA2_price_no2_sftp",
    "epex_IDA2_price_no3_sftp",
    "epex_IDA2_price_no4_sftp",
    "epex_IDA2_price_no5_sftp",
    "epex_IDA2_price_de_sftp",
    "epex_IDA2_price_at_sftp",
    "epex_IDA2_price_uk_sftp",
    "epex_IDA2_price_fr_sftp",
    "epex_IDA2_price_be_sftp",
    "epex_IDA2_price_fi_sftp",
    "epex_IDA2_price_nl_sftp",
    "epex_IDA2_price_ee_sftp",
    "epex_IDA2_price_lt_sftp",
    "epex_IDA2_price_lv_sftp",
    "epex_IDA2_price_pl_sftp",
    "epex_IDA3_price_dk1_sftp",
    "epex_IDA3_price_dk2_sftp",
    "epex_IDA3_price_se1_sftp",
    "epex_IDA3_price_se2_sftp",
    "epex_IDA3_price_se3_sftp",
    "epex_IDA3_price_se4_sftp",
    "epex_IDA3_price_no1_sftp",
    "epex_IDA3_price_no2_sftp",
    "epex_IDA3_price_no3_sftp",
    "epex_IDA3_price_no4_sftp",
    "epex_IDA3_price_no5_sftp",
    "epex_IDA3_price_de_sftp",
    "epex_IDA3_price_at_sftp",
    "epex_IDA3_price_uk_sftp",
    "epex_IDA3_price_fr_sftp",
    "epex_IDA3_price_be_sftp",
    "epex_IDA3_price_fi_sftp",
    "epex_IDA3_price_nl_sftp",
    "epex_IDA3_price_ee_sftp",
    "epex_IDA3_price_lt_sftp",
    "epex_IDA3_price_lv_sftp",
    "epex_IDA3_price_pl_sftp",
  ],
  "Epex Volume": [
    "epex_IDA1_volume_dk1_sftp",
    "epex_IDA1_volume_dk2_sftp",
    "epex_IDA1_volume_se1_sftp",
    "epex_IDA1_volume_se2_sftp",
    "epex_IDA1_volume_se3_sftp",
    "epex_IDA1_volume_se4_sftp",
    "epex_IDA1_volume_no1_sftp",
    "epex_IDA1_volume_no2_sftp",
    "epex_IDA1_volume_no3_sftp",
    "epex_IDA1_volume_no4_sftp",
    "epex_IDA1_volume_no5_sftp",
    "epex_IDA1_volume_de_sftp",
    "epex_IDA1_volume_at_sftp",
    "epex_IDA1_volume_uk_sftp",
    "epex_IDA1_volume_fr_sftp",
    "epex_IDA1_volume_be_sftp",
    "epex_IDA1_volume_fi_sftp",
    "epex_IDA1_volume_nl_sftp",
    "epex_IDA1_volume_ee_sftp",
    "epex_IDA1_volume_lt_sftp",
    "epex_IDA1_volume_lv_sftp",
    "epex_IDA1_volume_pl_sftp",
    "epex_IDA2_volume_dk1_sftp",
    "epex_IDA2_volume_dk2_sftp",
    "epex_IDA2_volume_se1_sftp",
    "epex_IDA2_volume_se2_sftp",
    "epex_IDA2_volume_se3_sftp",
    "epex_IDA2_volume_se4_sftp",
    "epex_IDA2_volume_no1_sftp",
    "epex_IDA2_volume_no2_sftp",
    "epex_IDA2_volume_no3_sftp",
    "epex_IDA2_volume_no4_sftp",
    "epex_IDA2_volume_no5_sftp",
    "epex_IDA2_volume_de_sftp",
    "epex_IDA2_volume_at_sftp",
    "epex_IDA2_volume_uk_sftp",
    "epex_IDA2_volume_fr_sftp",
    "epex_IDA2_volume_be_sftp",
    "epex_IDA2_volume_fi_sftp",
    "epex_IDA2_volume_nl_sftp",
    "epex_IDA2_volume_ee_sftp",
    "epex_IDA2_volume_lt_sftp",
    "epex_IDA2_volume_lv_sftp",
    "epex_IDA2_volume_pl_sftp",
    "epex_IDA3_volume_dk1_sftp",
    "epex_IDA3_volume_dk2_sftp",
    "epex_IDA3_volume_se1_sftp",
    "epex_IDA3_volume_se2_sftp",
    "epex_IDA3_volume_se3_sftp",
    "epex_IDA3_volume_se4_sftp",
    "epex_IDA3_volume_no1_sftp",
    "epex_IDA3_volume_no2_sftp",
    "epex_IDA3_volume_no3_sftp",
    "epex_IDA3_volume_no4_sftp",
    "epex_IDA3_volume_no5_sftp",
    "epex_IDA3_volume_de_sftp",
    "epex_IDA3_volume_at_sftp",
    "epex_IDA3_volume_uk_sftp",
    "epex_IDA3_volume_fr_sftp",
    "epex_IDA3_volume_be_sftp",
    "epex_IDA3_volume_fi_sftp",
    "epex_IDA3_volume_nl_sftp",
    "epex_IDA3_volume_ee_sftp",
    "epex_IDA3_volume_lt_sftp",
    "epex_IDA3_volume_lv_sftp",
    "epex_IDA3_volume_pl_sftp",
  ],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// Not used
// export const CURVES = [
//   "Volue pri no3 spot €/mwh cet h a",
//   "Volue pri no4 spot €/mwh cet h a",
//   "Volue pri no5 spot €/mwh cet h a",
//   "Volue pri fi spot €/mwh cet h a",
//   "Volue pri de spot €/mwh cet h a",
//   "Volue pri fr spot €/mwh cet h a",
//   "Volue pri at spot €/mwh cet h a",
//   "Volue pri be spot €/mwh cet h a",
//   "Volue pri nl spot €/mwh cet h a",
//   "Volue pri dk1 spot €/mwh cet h a",
//   "Volue pri dk2 spot €/mwh cet h a",
//   "Volue pri se1 spot €/mwh cet h a",
//   "Volue pri se2 spot €/mwh cet h a",
//   "Volue pri se3 spot €/mwh cet h a",
//   "Volue pri se4 spot €/mwh cet h a",
//   "Volue pri no1 spot €/mwh cet h a",
//   "Volue pri no2 spot €/mwh cet h a",
//   "Volue pri uk spot nord-pool £/mwh cet h a",
//   "Volue pri uk spot epex £/mwh cet h a",
//   "Volue pri uk spot nord-pool €/mwh cet h a",
//   "Volue pri uk spot epex €/mwh cet h a",
//   "energy_meteo_de_solar_prod",
//   "energy_meteo_de_wind_prod",
//   "energy_meteo_DK1_wind_prod",
//   "energy_meteo_DK1_solar_prod",
//   "energy_meteo_nl_wind_prod",
//   "energy_meteo_nl_solar_prod",
//   "energy_meteo_DK2_wind_prod",
//   "energy_meteo_DK2_solar_prod",
//   "energy_meteo_be_wind_prod",
//   "energy_meteo_be_solar_prod",
//   "energy_meteo_fr_wind_prod",
//   "energy_meteo_fr_solar_prod",
//   "energy_meteo_at_wind_prod",
//   "energy_meteo_gb_solar_prod",
//   "energy_meteo_gb_wind_prod",
//   "energy_meteo_ie_wind_prod",
//   "energy_meteo_es_wind_prod",
//   "energy_meteo_fi_wind_prod",
//   "energy_meteo_NO1_wind_prod",
//   "energy_meteo_NO2_wind_prod",
//   "energy_meteo_NO3_wind_prod",
//   "energy_meteo_NO4_wind_prod",
//   "energy_meteo_SE1_wind_prod",
//   "energy_meteo_SE2_wind_prod",
//   "energy_meteo_SE3_wind_prod",
//   "energy_meteo_SE4_wind_prod",
//   "energy_meteo_at_wind_prod_spot_delta",
//   "energy_meteo_be_solar_prod_spot_delta",
//   "energy_meteo_be_wind_prod_spot_delta",
//   "energy_meteo_de_solar_prod_spot_delta",
//   "energy_meteo_de_wind_prod_spot_delta",
//   "energy_meteo_fr_solar_prod_spot_delta",
//   "energy_meteo_fr_wind_prod_spot_delta",
//   "energy_meteo_gb_solar_prod_spot_delta",
//   "energy_meteo_gb_wind_prod_spot_delta",
//   "energy_meteo_ie_wind_prod_spot_delta",
//   "energy_meteo_nl_solar_prod_spot_delta",
//   "energy_meteo_nl_wind_prod_spot_delta",
//   "energy_meteo_nl_wind_prod_1h_delta",
//   "energy_meteo_nl_solar_prod_1h_delta",
//   "energy_meteo_be_wind_prod_1h_delta",
//   "energy_meteo_be_solar_prod_1h_delta",
//   "energy_meteo_fr_wind_prod_1h_delta",
//   "energy_meteo_fr_solar_prod_1h_delta",
//   "energy_meteo_at_wind_prod_1h_delta",
//   "energy_meteo_gb_solar_prod_1h_delta",
//   "energy_meteo_gb_wind_prod_1h_delta",
//   "energy_meteo_ie_wind_prod_1h_delta",
//   "energy_meteo_de_solar_prod_1h_delta",
//   "energy_meteo_de_wind_prod_1h_delta",
//   "energy_meteo_DK1_solar_prod_spot_delta",
//   "energy_meteo_DK1_wind_prod_spot_delta",
//   "energy_meteo_DK2_solar_prod_spot_delta",
//   "energy_meteo_DK2_wind_prod_spot_delta",
//   "energy_meteo_DK1_wind_prod_1h_delta",
//   "energy_meteo_DK1_solar_prod_1h_delta",
//   "energy_meteo_DK2_wind_prod_1h_delta",
//   "energy_meteo_DK2_solar_prod_1h_delta",
//   "energy_meteo_SE1_wind_prod_spot_delta",
//   "energy_meteo_SE2_wind_prod_spot_delta",
//   "energy_meteo_SE3_wind_prod_spot_delta",
//   "energy_meteo_SE4_wind_prod_spot_delta",
//   "energy_meteo_NO1_wind_prod_spot_delta",
//   "energy_meteo_NO2_wind_prod_spot_delta",
//   "energy_meteo_NO3_wind_prod_spot_delta",
//   "energy_meteo_NO4_wind_prod_spot_delta",
//   "energy_meteo_fi_wind_prod_spot_delta",
//   "energy_meteo_SE1_wind_prod_1h_delta",
//   "energy_meteo_SE2_wind_prod_1h_delta",
//   "energy_meteo_SE3_wind_prod_1h_delta",
//   "energy_meteo_SE4_wind_prod_1h_delta",
//   "energy_meteo_NO1_wind_prod_1h_delta",
//   "energy_meteo_NO2_wind_prod_1h_delta",
//   "energy_meteo_NO3_wind_prod_1h_delta",
//   "energy_meteo_NO4_wind_prod_1h_delta",
//   "energy_meteo_fi_wind_prod_1h_delta",
// ];
