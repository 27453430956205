/* global Excel, console */

import moment from "moment";

export function createSheet(name: string) {
  return Excel.run(async function (context) {
    try {
      const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
      await context.sync();

      if (worksheet.isNullObject) return context.workbook.worksheets.add(name);

      return worksheet;
    } catch (err) {
      console.error("error", err);
      await context.sync();
      return null;
    }
  });
}

export function activateSheet(sheet: Excel.Worksheet) {
  return Excel.run(async function (context) {
    try {
      sheet.activate();
    } catch (err) {
      throw err;
    }
    return await context.sync();
  });
}

export function readValueFromSelectedCells() {
  return Excel.run(async (context) => {
    const selected = context.workbook.getSelectedRange();
    context.load(selected);
    selected.load("values");
    await context.sync();

    return selected.values.flat(2);
  });
}

export async function getSelectedAddress() {
  return Excel.run(async (context) => {
    const selected = context.workbook.getSelectedRange();
    context.load(selected);
    const first = selected.getCell(0, 0);
    first.load("address");
    const last = selected.getLastCell();
    last.load();
    await context.sync();

    return [first.address, last.address];
  });
}

export async function readDateFromSelectedCells() {
  const selected = await readValueFromSelectedCells();

  if (selected.length !== 2) {
    throw new Error("exctly two cells must be selcted");
  }
  const moments = selected.map((str) => {
    const mom = excelDateToMoment(str);
    return mom.unix();
  });
  const start = moment.unix(Math.min(...moments));
  const end = moment.unix(Math.max(...moments));

  return { startDate: start, endDate: end };
}

export async function readValueFromAddress(address: string) {
  return await Excel.run(async (context) => {
    let sheet, r;
    const addressParts = address.split("!");
    if (addressParts.length === 2) {
      sheet = context.workbook.worksheets.getItemOrNullObject(addressParts[0]);
      sheet.load("isNullObject");
      await context.sync();
      if (sheet.isNullObject) {
        throw new Error(`Wrong address, sheet with name ${addressParts[0]} does not exist.`);
      }
      r = sheet.getRange(addressParts[1]).getCell(0, 0);
    } else {
      console.warn(`Address ${address} does not contain sheet name, consider format 'sheet_name!cell_start:cell_end'`);
      sheet = context.workbook.worksheets.getActiveWorksheet();
      r = sheet.getRange(address).getCell(0, 0);
    }
    r.load("values");
    await context.sync();
    const val = r.values[0];

    return val;
  });
}

export async function readDateFromAddress(address: string) {
  const val = await readValueFromAddress(address);
  return excelDateToMoment(val);
}

export async function getAuth() {
  return await OfficeRuntime.auth
    .getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    })
    .catch(
      async () =>
        // there is some throtling mechanism which stops blocking the api requests after a few calls
        // MS recommends no using the allowSignInPrompt | allowConsentPrompt to work around this
        // https://github.com/OfficeDev/office-js/issues/3298#issuecomment-2083377995
        await OfficeRuntime.auth.getAccessToken({
          forMSGraphAccess: true,
        })
    )
    .catch((err) => {
      throw err;
    });
}

export async function getTableLocation(userDefinedVal: string, sheet: Excel.Worksheet, context: Excel.RequestContext) {
  let leftCornerX = 1;
  let leftCornerY = 1;
  if (userDefinedVal) {
    const leftCorner = sheet.getRange(`${userDefinedVal}:${userDefinedVal}`);
    leftCorner.load(["rowIndex", "columnIndex"]);
    await context.sync().catch((err) => {
      throw err;
    });

    // eslint-disable-next-line office-addins/call-sync-after-load, office-addins/call-sync-before-read
    leftCornerY = leftCorner.rowIndex;
    // eslint-disable-next-line office-addins/call-sync-after-load, office-addins/call-sync-before-read
    leftCornerX = leftCorner.columnIndex;
  }

  return { x: leftCornerX, y: leftCornerY };
}

export async function clearTables(sheet: Excel.Worksheet, tables: string[]) {
  return await Excel.run(async (context) => {
    const objects = tables.map((t) => sheet.tables.getItemOrNullObject(t));
    await context.sync();

    var proms = objects.map((o) => {
      o.context.load(o.rows);
      return o.context.sync();
    });
    await Promise.all(proms);
    objects.map((o) => {
      // await o.context.sync();
      o.clearFilters();
      o.rows.deleteRows(o.rows.items);
    });
    await context.sync();
  });
}

function excelDateToMoment(excelDate) {
  // Excel date is the number of days since January 1, 1900
  return moment(Math.round((excelDate - 25569) * 86400 * 1000));
}
