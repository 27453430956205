import {
  fastNumberField,
  fastListbox,
  fastOption,
  fastTextField,
  provideFASTDesignSystem,
  fastBadge,
  fastSwitch,
  fastDivider,
  fastButton,
  fastAnchoredRegion,
  fastAccordion,
  fastAccordionItem,
  fastDisclosure,
  accentColor,
  neutralPalette,
  accentPalette,
  SwatchRGB,
  PaletteRGB,
  baseLayerLuminance,
  StandardLuminance,
} from "@microsoft/fast-components";
import { parseColorHexRGB } from "@microsoft/fast-colors";

/* global console, document */

console.log("Registering FAST-components");
const color = parseColorHexRGB("#107C10");
const neutral = parseColorHexRGB("#718E71");
const accent = parseColorHexRGB("#228622");
const pal = PaletteRGB.from(color);
const palNeut = PaletteRGB.from(neutral);
neutralPalette.withDefault(palNeut);
accentPalette.withDefault(pal);
accentColor.withDefault(SwatchRGB.from(accent));

provideFASTDesignSystem()
  .register(
    fastListbox(),
    fastOption(),
    fastTextField(),
    fastBadge(),
    fastNumberField(),
    fastSwitch(),
    fastDivider(),
    fastButton(),
    fastAnchoredRegion(),
    fastAccordion(),
    fastAccordionItem(),
    fastDisclosure()
  )
  .withDesignTokenRoot(document.documentElement);

export const setColorScheme = (dark: boolean) => {
  if (dark) baseLayerLuminance.setValueFor(document.body, StandardLuminance.DarkMode);
  else baseLayerLuminance.setValueFor(document.body, StandardLuminance.LightMode);
};
