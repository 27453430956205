import { ListboxOption } from "@microsoft/fast-components";
import { ListboxElement } from "@microsoft/fast-foundation";
import $ from "jquery";

const optionComparator = (a: ListboxOption, b: ListboxOption): number => {
  if (a.selected && !b.selected) return -1;
  else if (!a.selected && b.selected) return +1;

  return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
};
/* global */

const CurvesList = (element: ListboxElement) => {
  let elements = [];
  let options: ListboxOption[] = [];

  element.addEventListener("click", () => {
    sortList();
  });

  const loadList = (curves: string[], selected: string[]) => {
    elements = curves;
    options = elements
      .map((name) => {
        const el = new ListboxOption(name, name);
        el.selected = selected.includes(name.toLowerCase());
        el.classList.add("list-option");
        return el;
      })
      .sort(optionComparator);
    options.forEach((o) => element.appendChild(o));
  };

  const filterCurves = (search: string) => {
    sortList();
    if (!search) {
      element.options.forEach((o) => (o.style.display = ""));
      return;
    }
    const searchSplit = search.split(";").map((str) => str.trim());
    element.options.forEach(
      (o) =>
        (o.style.display =
          !searchSplit.some((split) => o.value.toLowerCase().includes(split.toLowerCase())) && !o.selected
            ? "None"
            : "")
    );
  };

  const getSelected = () => {
    return element.options.filter((o) => o.selected);
  };

  const selectAll = () => {
    element.options.forEach((o) => {
      o.selected = true;
    });
  };

  const deselectAll = () => {
    element.options.forEach((o) => {
      o.selected = false;
    });
  };

  const sortList = () => {
    $(element.children).each(function (i, item) {
      $(item).remove();
    });
    element.options.sort(optionComparator).forEach((opt) => element.appendChild(opt));
  };

  return { loadList, filterCurves, selectAll, deselectAll, getSelected };
};

export default CurvesList;
